import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import closedpic from '../../img/C3-popup-Vakantie_Kerst-2024.png'

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`

const Button = styled.button`
  width: 30px;
  height: 30px;
  background: ${({ theme }) => theme.color.contrast};
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -15px;
  top: -15px;
`

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     width: 600,
//     inset: undefined
//   },

// };

export default function ClosedModal() {
  const [showModal, setShowModal] = React.useState(false)
  useEffect(() => {
    const item = sessionStorage.getItem('modal2024')

    if (item !== 'true') {
      setTimeout(() => {
        setShowModal(true)
      }, 1500)
    }
  }, [])

  function closeModal() {
    sessionStorage.setItem('modal2024', 'true')
    setShowModal(false)
  }

  return (
    <ReactModal
      className="vakantie"
      onRequestClose={() => setShowModal(false)}
      isOpen={showModal}
    >
      <Image src={closedpic} alt="Vakantie 2023" />

      <Button onClick={() => closeModal()} type="button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="feather feather-x"
          viewBox="0 0 24 24"
        >
          <path d="M18 6L6 18" />
          <path d="M6 6L18 18" />
        </svg>
      </Button>
    </ReactModal>
  )
}
